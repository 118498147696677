html,
body {
    width: 100%;
    height: 100%;
    display: flex;
    font-family: "SkolarSansPeSemibold" !important;
}

.instructionSymbol {
    color: #e6007e !important;
}

.layoutArea {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

}

main {
    flex-grow: 1;
}

header,
main,
footer {
    flex-shrink: 0;
}

#root {
    width: 100%
}


::-moz-selection {
    background: #e6007e;
    color: white;
}

::selection {
    background: #e6007e;
    color: white;
}

body h3 {
    font-size: 13pt;
    font-weight: bold;
}

@font-face {
    font-family: 'SkolarSansPeRegular';
    src: local('SkolarSansPEWeb-Rg.woff2'), url(./SkolarSansPEWeb-Rg.woff2) format('woff2');
}

@font-face {
    font-family: 'SkolarSansPeSemibold';
    src: local('SkolarSansPEWeb-Sb.woff2'), url(./SkolarSansPEWeb-Sb.woff2) format('woff2');
}

a.MuiMenuItem-root:hover {
    color: unset;
}

/* fix for react-doc-viewer document counter margin getting overwritten by MUI Reboot.css */
#doc-nav-info {
    margin-top: 16px !important;
}

.downloadable {
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    margin: 2px;
}
